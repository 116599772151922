import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'
import { ContactData, ContactFormData } from '@/models/contact'
import { MyVketSelectItem } from '@/models/ui'
import {
  submitContactResponseSchema,
  SubmitContactResponse,
} from '@/composables/repository/useContactRepository'

export const useContact = () => {
  const repositoryFactory = useRepositoryFactory()
  const contactRepository = repositoryFactory.get('contact')

  const contactData = ref<ContactFormData>({
    email: '',
    contactEmail: '',
    name: '',
    vketId: '',
    category: '',
    body: '',
    occurredAt: '',
    image1: null,
    image2: null,
    image3: null,
    device: '',
    forcedPrivateItem: '',
    os: '',
    osOther: '', // その他を選択した場合のみ
    browser: '',
    browserOther: '', // その他を選択した場合のみ
    agree: false,
    occurredWorld: '',
    occurredFrequency: '',
    violatingUser: '',
    victimizedUser: '',
  })

  const setContactData = (data: ContactFormData) => {
    contactData.value = data
  }

  const submitContact = async (contactData: ContactData) => {
    const response = await contactRepository.post.submitContact(contactData)
    if (!response) {
      throw new Error('response is empty.')
    }
    if (
      !existResponse<SubmitContactResponse>(
        submitContactResponseSchema,
        response
      )
    ) {
      console.error('An API response is different.')
    }
    return response
  }

  return {
    contactData,
    setContactData,
    submitContact,
  }
}

export type ContactComposable = ReturnType<typeof useContact>

export const contactComposableInjectionKey: InjectionKey<ContactComposable> =
  Symbol('contact-composable')
